import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

import Info from "../Info";

import NIU_BIU from "../../pdf/metering/NIU BIU.pdf";
import GWF_Cold_Water_Datasheet from "../../pdf/metering/GWF cold water datasheet.pdf";
import GWF_Warmwater_Datasheet from "../../pdf/metering/GWF warmwater datasheet.pdf";
import COBA_VM_FI2019 from "../../pdf/metering/COBA_VM_FI2019.pdf";
import COBA_VM_FI2021 from "../../pdf/metering/COBA Vedenmittaus esite.pdf";

import Työselitysmalli_impulssijärjestelmä from "../../pdf/metering/Työselitysmalli impulssijärjestelmä.doc";
import Työselitysmalli_langaton from "../../pdf/metering/Työselitysmalli langaton.doc";
import Työselitysmalli_MBus from "../../pdf/metering/Työselitysmalli MBus.doc";
import Työselitysmalli_MBus2 from "../../pdf/metering/Työselitysmalli MBus langallinen 09-03-2021.docx";

import Periaatekaavio from "../../pdf/metering/Tyypillinen_kaavio_Mbus.dwg";

import Asennusohje from "../../pdf/metering/Asennusohje.pdf";
import Asennusohje2 from "../../pdf/metering/Asennusohje MBus langallinen 09-03-2021.pdf";



import COBA_MBus_Periaatekaavio from "../../pdf/metering/COBA MBus periaatekaavio.pdf";
import COBA_MBus_Vedenmittausjärjestelmä from "../../pdf/metering/COBA MBus vedenmittausjärjestelmä.pdf";
import COBA_MBus2021 from "../../pdf/metering/Asennusohje MBus langallinen 09-03-2021.pdf";

import Etäpalvelun_käyttöohje from "../../pdf/metering/Etäpalvelun käyttöohje.pdf";
import Etäpalvelun_käyttöohje2 from "../../pdf/metering/Lonix_Vedenmittaus_kayttoohje_etaluenta_211103.pdf";

import ImgPdf from "../../stylesheets/images/ImgPdf.png";
import ImgDwg from "../../stylesheets/images/img_dwg.png";


class Metering extends Component {
    render() {
        const { t } = this.props;

        return (
            <div className="metering">

                <Info pageName="metering" pdfs={[COBA_VM_FI2021, Etäpalvelun_käyttöohje2]}/>

                <div className="longBody">

                    <h3>{t('metering.body.h_1')}</h3>
                    <p>{t('metering.body.t_1')}</p>

                    <h3>{t('metering.body.h_2')}</h3>
                    <p>{t('metering.body.t_2')}</p>

                    <div className="withPhoto" />

                    <div className="pdf">
                        <h2>{t('metering.body.h_3')}</h2>
                        <table>
                            <caption>{t('metering.h_3')}</caption>
                            <tbody>
                            <tr><th></th><td><span>Ratkaisuesitys</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={COBA_VM_FI2019} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th></th><td><span>Vesimittari (M-Bus langallinen kylmävesi)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={GWF_Cold_Water_Datasheet} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th></th><td><span>Vesimittari (M-Bus langallinen lämminvesi)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={GWF_Warmwater_Datasheet} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th></th><td><span>Järjestelmän keskusyksikkö</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={NIU_BIU} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('metering.h_4')}</caption>
                            <tbody>
                            <tr><th></th><td><span>Työselitysmalli (M-Bus) (Word)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={Työselitysmalli_MBus2} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                       </tbody>
                        </table>
                        <table>
                            <caption>{t('metering.h_5')}</caption>

                            <tbody>
                            <tr><th></th><td><span>Asennusohje ja periaatekuva (M-Bus)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={Asennusohje2} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th></th><td><span>Periaatekaavio Mbus langallinen (pdf)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={COBA_MBus_Periaatekaavio} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th></th><td><span>Periaatekaavio (Mbus) (Dwg)</span></td><td className="pdfLinkDL"><img className="ImgDwg" src={ImgDwg} alt=""/><a href={Periaatekaavio} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('metering.h_6')}</caption>
                            <tbody>
                            <tr><th></th><td><span>Etäluentapalvelun käyttöohje</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={Etäpalvelun_käyttöohje2} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(Metering);
