import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Info from "./Info";

class Contact extends Component {
    render() {
        const { t } = this.props;

        return (
            <div className="contact">

                <Info pageName="contact" pdfs={[]}/>

                <div className="longBody">

                    <div>
                    <h3>{t('contact.body.h3_1')}</h3>
                    <p>{t('contact.body.p_1_0')}</p>
                    </div>

                    <div>
                    <h3>{t('contact.body.h3_2')}</h3>
                    <p>{t('contact.body.p_2_0')}</p>
                    </div>

                    <div>
                    <h3>{t('contact.body.h3_3')}</h3>
                    <p>{t('contact.body.p_3_0')}</p>
                    </div>

                    <div>
                    <h3>{t('contact.body.h3_4')}</h3>
                    <p>{t('contact.body.p_4_0')}</p>
                    </div>

                    <div>
                    <h3>{t('contact.body.h3_5')}</h3>
                    <p>{t('contact.body.p_5_0')}</p>
                    </div>

                    <div>
                        <h3>{t('contact.body.h3_6')}</h3>
                        <p>{t('contact.body.p_6_0')}</p>
                    </div>


                </div>

            </div>
        );
    }
}

export default withTranslation()(Contact);
