import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

import 'core-js/es/map';
import 'core-js/es/set';

import React from "react";
import ReactDOM from "react-dom";
import Main from "./Main";

import './locals/i18n';

import "./stylesheets/index.css";
import "./stylesheets/header.css";
import "./stylesheets/content.css";
import "./stylesheets/footer.css";
import "./stylesheets/pagespecific.css";

ReactDOM.render(
    <Main />,
    document.getElementById("root")
);
