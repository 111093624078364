import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

class News extends Component {
    componentDidUpdate() {
    }

    componentWillUnmount() {
    }

    render() {
        const { t } = this.props;

        return (
            <div className="news">

                <div className="longBody">

                    <div className="newsArticle">
                        <p className="newsDate">{t('news.body.t_3')} 03.11.2021</p>
                        <h3>KITA</h3>
                        <a  target='_blank' href={"https://joom.ag/uN2I/p41"}>Linkki KITA-lehteen</a>
                        <div className="newsLink"><div>  </div></div>
                    </div>

                    <div className="newsArticle">
                        <p className="newsDate">{t('news.body.t_3')} 1.8.2019</p>
                        <h3>{t('news.articles.n_1_h')}</h3>
                        <p>{t('news.articles.n_1_t')}</p>
                        <div className="newsLink"><div>  <a href={'#/'}><span>{t('news.body.t_link')}</span></a></div></div>
                    </div>

                    <div className="newsArticle">
                        <h3>Situations Aware Security Operations Center</h3>
                        <p>Lonix participates in the European wide collaborative project SAWSOC (www.sawsoc.eu).</p>
                        <div className="newsLink"><div>  <a href={'#/notfound'}><span>News release</span></a></div></div>
                    </div>

                    <div className="newsArticle">
                        <h3>Case Studies of International Lonix Sites</h3>
                        <div className="newsLink">
                            <div>  <a href={`${require('../pdf/news/CaseStudy_PTI.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Case Study: IBMS at the Premier Inn Hotel</span></a></div>
                            <div>  <a href={`${require('../pdf/news/CaseStudy_Mazaya.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Case Study: IBMS at the Prestigious Complex of Three Business Towers</span></a></div>
                            <div>  <a href={`${require('../pdf/news/CaseStudy_Opsut.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Case Study: Remote Control and Monitoring in the Nationwide Fiber Network</span></a></div>
                            <div>  <a href={`${require('../pdf/news/CaseStudy_HiltonDeadSea.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Case Study: Lonix IBMS at the Hilton Dead Sea Resort</span></a></div>
                            <div>  <a href={`${require('../pdf/news/CaseStudy_Kamchia.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Case Study: Automation & Security at Multifunctional Sports Complex</span></a></div>
                            <div>  <a href={`${require('../pdf/news/CaseStudy_SBI.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Case Study: Fully Integrated Solution in Luxurious Holiday Resort</span></a></div>
                            <div>  <a href={`${require('../pdf/news/CaseStudy_PSU.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Case Study: Integrated Security Systems at University Campus</span></a></div>
                        </div>
                    </div>

                    <div className="newsArticle">
                        <p className="newsDate">{t('news.body.t_3')} 1.4.2016</p>
                        <h3>Lonix Vedenmittaus laajenee - markkinoiden monipuolisin järjestelmä </h3>
                        <p>LONIX Vedenmittausjärjestelmä täydentyy langattomilla mittareilla. Järjestelmä on nyt markkinoiden monipuolisin.</p>
                        <div className="newsLink"><div>  <a href={`${require('../pdf/news/Lonix_Vedenmittaus_news_160401.pdf')}`} target="_blank" rel="noopener noreferrer"><span>{t('news.body.t_2')}</span></a></div></div>
                    </div>


                    <div className="newsArticle">
                        <p className="newsDate">{t('news.body.t_3')} 30.9.2014</p>
                        <h3>Convenient User Interface for BMS</h3>
                        <p>Lonix releases easy Touch Screen access to Lonix BMS direct from the DDC Panel.</p>
                        <div className="newsLink">
                            <div>  <a href={`${require('../pdf/news/Lonix_product_release_140930.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Press release</span></a></div>
                            <div>  <a href={`${require('../pdf/news/Datasheet_LX-TSP10.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Datasheet</span></a></div>
                        </div>
                    </div>

                    <div className="newsArticle">
                        <p className="newsDate">{t('news.body.t_3')} 1.11.2013</p>
                        <h3>Finnish Automation to Latin America</h3>
                        <p>Lonix delivers a nationwide control and monitoring system for the leading telecom operator CANTV in Venezuela.</p>
                        <div className="newsLink"><div>  <a href={`${require('../pdf/news/Lonix_SouthAmerica_news_131101.pdf')}`} target="_blank" rel="noopener noreferrer"><span>{t('news.body.t_1')}</span></a></div></div>
                    </div>

                    <div className="newsArticle">
                        <p className="newsDate">{t('news.body.t_3')} 3.3.2013</p>
                        <h3>New generation of IP cameras to expand the selection of Lonix cameras</h3>
                        <p>The intelligent building system provider Lonix Ltd. (Finland) expands its camera selection with new generation of ONVIF compatible IP cameras.</p>
                        <div className="newsLink"><div>  <a href={`${require('../pdf/news/Lonix_product_release_130306.pdf')}`} target="_blank" rel="noopener noreferrer"><span>{t('news.body.t_1')}</span></a></div></div>
                    </div>

                    <div className="newsArticle">
                        <p className="newsDate">{t('news.body.t_3')} 12.9.2011</p>
                        <h3>FLEX Series Controllers - Peak Performance with Powerful Flexibility</h3>
                        <p>Lonix releases new controller series packed up with power and flexibility - new FLEX Series Controllers to create Flexible Simplicity in Complex Control Systems.</p>
                        <div className="newsLink"><div>  <a href={`${require('../pdf/news/Lonix_product_release_110912.pdf')}`} target="_blank" rel="noopener noreferrer"><span>{t('news.body.t_1')}</span></a></div></div>
                        {/*<p>{t('news.body.t_4')}<span>Mika Löytynoja</span></p>*/}
                    </div>

                </div>

            </div>
        );
    }
}
export default withTranslation()(News);
