import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";

import Info from "../Info";

import ImgVideo from "../../stylesheets/images/video-monitor.jpg";
import ImgCamera from "../../stylesheets/images/video-camera.jpg";
import ImgV100 from "../../stylesheets/images/v100.jpg";
import ImgVIP2R from "../../stylesheets/images/vIP2r.jpg";
import ImgVIP2W from "../../stylesheets/images/vIP2w.jpg";
import ImgComp from "../../stylesheets/images/sec-components.jpg";
import ImgPdf from "../../stylesheets/images/ImgPdf.png";

import System_Catalogue from "../../pdf/support/Lonix_System_Catalogue.pdf";
import v1000 from '../../pdf/security/surveillance/Datasheet_LX-SEC-V1000.pdf';
import v100 from '../../pdf/security/surveillance/Datasheet_LX-SEC-V100.pdf';
import v200 from '../../pdf/security/surveillance/Datasheet_LX-SEC-V200.pdf';
import v300 from '../../pdf/security/surveillance/Datasheet_LX-SEC-V300.pdf';
import v2000 from '../../pdf/security/surveillance/Datasheet_LX-SEC-V2000.pdf';
import IP2_RW from '../../pdf/security/components/Datasheet_LX-SEC-V-IP2-RW.pdf';
import IP2_W from '../../pdf/security/components/Datasheet_LX-SEC-V-IP2-W.pdf';

class Security extends Component {

    render() {
        const { t, match } = this.props;

        return (
            <div className="security">

                <Info pageName="security" pdfs={[]}/>

                <div className="wrapper" >

                    <div className="longBody">

                        <li className="pComponents"><NavLink to={`${match.url}/components`}/>
                            <aside>
                                <h4>{t('header.components')}</h4>
                                <span>{t('components.info.t_1')}</span>
                                <NavLink to={`${match.url}/components`} className="vsLink">{t('products.body.link')}</NavLink>
                            </aside>
                        </li>
                        <li className="pAccesscontrolsystem"><NavLink to={`${match.url}/accesscontrolsystem`}/>
                            <aside>
                                <h4>{t('header.accesscontrolsystem')}</h4>
                                <span>{t('accesscontrolsystem.info.t_1')}</span>
                                <NavLink to={`${match.url}/accesscontrolsystem`} className="vsLink">{t('products.body.link')}</NavLink>
                            </aside>
                        </li>
                        <li className="pVideosurveillance"><NavLink to={`${match.url}/videosurveillance`}/>
                            <aside>
                                <h4>{t('header.videosurveillance')}</h4>
                                <span>{t('videosurveillance.info.t_1')}</span>
                                <NavLink to={`${match.url}/videosurveillance`} className="vsLink">{t('products.body.link')}</NavLink>
                            </aside>
                        </li>
                    </div>
                </div>

            </div>
        );
    }
}
export default withTranslation()(Security);

export class SecurityComponents extends Component {
    constructor(props) {
        super(props);
        this.scrollToCameras = this.scrollToCameras.bind(this);
    }
    componentDidMount() {
        if(this.props.location.search.indexOf('camera') !== -1){
            this.scrollToCameras();
        }
    }
    scrollToCameras = () => {
        document.querySelector('.cameras').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    };

    render() {
        const { t } = this.props;

        return (
            <div className="components">

                <Info pageName="components" pdfs={[]}/>

                <div className="longBody">
                    <div className="withPhoto">
                        <p style={{marginTop: '15px'}}>{t('components.info.t_1')}</p>
                        <img className="ImgBms" src={ImgComp} alt="" />
                    </div>

                    <div className="pdf">
                        <h2>{t('components.h_1')}</h2>
                        <table>
                            <caption>{t('components.h_1_1')}</caption>
                            <tbody>
                            <tr><th>LX-SEC-V1000</th><td><span>Network Controller</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-V1000.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-V100</th><td><span>Reader Interface Panel</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-V100.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-V200</th><td><span>Input Interface Panel</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-V200.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-V300</th><td><span>Output Interface Panel</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-V300.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-V2000</th><td><span>Network Controller & Reader Interface Panel</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-V2000.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-V-IP2-R</th><td><span>Door Controller/Reader & Interface Module (formerly LX-SEC-ER40-C)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-V-IP2-R.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-V-IP2-RW</th><td><span>Door Controller/Reader & Interface Module{"\n"}(formerly LX-SEC-ER40W-C)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-V-IP2-RW.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-V-IP2-W</th><td><span>Single Door Controller{"\n"}(formerly LX-SEC-V-IP-C)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-V-IP2-W.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>READERS</caption>
                            <tbody>
                            <tr><th>iCLASS</th><td><span>iCLASS readers, R series</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Access_Readers_HID_iclass_r_series.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>iPASS</th><td><span>iPASS readers</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Access_Readers_ipass_series.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="pdf cameras">
                        <h2>{t('components.h_2')}</h2>
                        <table>
                            <caption>{t('components.h_2_1')}</caption>
                            <tbody>
                            <tr><th>LX-SEC-CFMD-IP-720P</th><td><span>High Definition fixed IP mini dome camera</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/cameras/Datasheet LX-SEC-CFMD-IP-720P.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFD1-IP-720P-IR</th><td><span>High Definition (HD) IP dome camera, varifocal lens, IR 15m, 1 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/cameras/Datasheet_LX-SEC-CFD1-IP-720P-IR.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFMD-IP-1080P</th><td><span> FullHD fixed IP mini dome camera</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/cameras/Datasheet LX-SEC-CFMD-IP-1080P.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFD2-IP-1080P-IR</th><td><span>Full HD fixed IP dome camera, 2 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/cameras/Datasheet_LX-SEC-CFD2-IP-1080P-IR.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFD2-IP-4M-IR</th><td><span>Full HD fixed IP dome camera, 4 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/cameras/Datasheet_LX-SEC-CFD2-4M-IR.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFD2-IP-5M-IR</th><td><span>Full HD fixed IP dome camera, 5 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/cameras/Datasheet_LX-SEC-CFD2-IP-5M-IR.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFD2-IP-8M-IR</th><td><span>4K Ultra HD fixed IP dome camera, 8 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/cameras/Datasheet_LX-SEC-CFD2-IP-8M-IR.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFD2-IP-1080P-IR-60FPS</th><td><span>High Frame Rate Full HD fixed IP dome camera, WDR, 60 FPS</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/cameras/Datasheet_LX-SEC-CFD2-IP-1080P-IR-60FPS.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('components.h_2_3')}</caption>
                            <tbody>
                            <tr><th>LX-SEC-CFB2-IP-4M</th><td><span>Full High Definition (Full HD) fixed IP box camera, 4 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-CFB2-IP-4M.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFB2-IP-5M</th><td><span>Full High Definition (Full HD) fixed IP box camera, 5 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-CFB2-IP-5M.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr className="hiddenRow"><th>LX-SEC-CFB3-IP-720P</th><td><span>High Definition (HD) fixed IP box camera, 1 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-CFB3-IP-720P.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFB2-IP-1080P</th><td><span>Full High Definition (Full HD) fixed IP box camera, 2 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet LX-SEC-CFB2-IP-1080P e.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFB2-IP-1080P-{"\n"}60FPS</th><td><span>High Frame Rate Full HD fixed IP box camera, WDR, 60 FPS</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet LX-SEC-CFB2-IP-1080P-60FPS.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('components.h_2_4')}</caption>
                            <tbody>
                            <tr><th>LX-SEC-CFD3-IP-1080P-O-IR</th><td><span>Full HD fixed IP vandal dome camera, 2 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-CFD3-IP-1080P-O-IR.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFD3-IP-1080P-O-IR-Z4.2</th><td><span>Full HD fixed IP vandal dome camera, 4.2x optical zoom, 2 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-CFD3-IP-4M-O-IR-Z4.2.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFD3-IP-4M-O-IR</th><td><span>Full HD fixed IP vandal dome camera, 4 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-CFD3-IP-4M-O-IR.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFD3-IP-4M-O-IR-Z4.2</th><td><span>Full HD fixed IP vandal dome camera, 4.2x optical zoom 4 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-CFD3-IP-4M-O-IR-Z4.2.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFD3-IP-5M-O-IR</th><td><span>Full HD fixed IP vandal dome camera, 5 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-CFD3-IP-5M-O-IR.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFD3-IP-1080P-O-IR-60FPS</th><td><span>High Frame Rate Full HD fixed IP vandal dome camera, WDR, 2 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-CFD3-IP-1080P-O-IR-60FPS.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('components.h_2_5')}</caption>
                            <tbody>
                            <tr><th>LX-SEC-CFBU2-IP-1080P-O-IR50-70</th><td><span>Full HD fixed IP bullet camera, IR 50/70m, 2 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-CFBU2-IP-1080P-O-IR50-70.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFBU3-IP-1080P-O-IR25</th><td><span>Full HD fixed IP bullet camera, IR 25m, 2 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-CFBU3-IP-1080P-O-IR25.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFBU4-IP-4M-O-IR30</th><td><span>Full HD fixed IP bullet camera, IR 30m, 4Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-CFBU4-IP-4M-O-IR30.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFBU4-IP-4M-O-IR30-Z4.2</th><td><span>Full HD fixed IP bullet camera, 4.2x optical zoom, IR 30m, 4Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-CFBU4-IP-4M-O-IR30-Z4.2.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFBU4-IP-5M-O-IR30</th><td><span>Full HD fixed IP bullet camera, IR 30m, 5 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-CFBU4-IP-5M-O-IR30.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFBU4-IP-8M-O-IR30</th><td><span>4K Ultra HD fixed IP bullet camera, IR 30m, 8Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-CFBU4-IP-8M-O-IR30.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('components.h_2_2')}</caption>
                            <tbody>
                            <tr><th>LX-SEC-CFD2-IP-5M-FE360</th><td><span>Fish Eye Panorama dome camera, 5 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/cameras/Datasheet LX-SEC-CFD2-IP-5M-FE360.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CFD2-IP-5M-FE360-O</th><td><span>Fish Eye Panorama vandal dome camera, 5 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/cameras/Datasheet LX-SEC-CFD2-IP-5M-FE360-O.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('components.h_2_6')}</caption>
                            <tbody>
                            <tr><th>LX-SEC-CPTZD-IP-1080P-20Z16-O</th><td><span>Outdoor High Speed IP PTZ camera, 20x optical zoom, Full HD</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/cameras/Datasheet_LX-SEC-CPTZD-IP-1080P-20Z16-O.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CPTZD-IP-1080P-30Z16-O</th><td><span>Outdoor High Speed IP PTZ camera, 30x optical zoom, Full HD</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/cameras/Datasheet_LX-SEC-CPTZD-IP-1080P-30Z16-O.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CPTZD-IP-4M-10Z16-O-IR</th><td><span>Outdoor High Speed IP PTZ camera, 10x optical zoom, IR 100m, 4 Mpix</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/cameras/Datasheet_LX-SEC-CPTZD-IP-4M-10Z16-O-IR.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="pdf">
                        <h2>{t('components.h_3')}</h2>
                        <table>
                            <caption>{t('components.h_3_1')}</caption>
                            <tbody>
                            <tr><th>LX-SEC-CBR-Dx</th><td><span>Brackets for dome cameras</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-CBR-Dx.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CBR-Bx</th><td><span>Brackets for box and bullet cameras</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-CBR-Bx.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SEC-CL</th><td><span>CS mount camera lens for Box cameras</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/security/components/Datasheet_LX-SEC-CL.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        );
    }
}

export class AccessControlSystem extends Component {
    render() {
        const { t } = this.props;

        return (
            <div className="accesscontrolsystem">

                <Info pageName="accesscontrolsystem" pdfs={[System_Catalogue, v1000, v100, v200, v300]}/>

                <div className="wrapper">

                    <div className="longBody" >

                        <div className="v1000"><li><img alt="" src={ImgV100}/>
                            <aside>
                                <h4>{t('accesscontrolsystem.v1000_h')}</h4>
                                <span>{t('accesscontrolsystem.v1000_t')}</span>
                                <a href={v1000} target="_blank" rel="noopener noreferrer" className="vsLink">Read more</a>
                            </aside>
                        </li></div>

                        <div className="v100"><li><img alt="" src={ImgV100}/>
                            <aside>
                                <h4>{t('accesscontrolsystem.v100_h')}</h4>
                                <span>{t('accesscontrolsystem.v100_t')}</span>
                                <a href={v100} target="_blank" rel="noopener noreferrer" className="vsLink">Read more</a>
                            </aside>
                        </li></div>
                        <div className="v200"><li><img alt="" src={ImgV100}/>
                            <aside>
                                <h4>{t('accesscontrolsystem.v200_h')}</h4>
                                <span>{t('accesscontrolsystem.v200_t')}</span>
                                <a href={v200} target="_blank" rel="noopener noreferrer" className="vsLink">Read more</a>
                            </aside>
                        </li></div>
                        <div className="v300"><li><img alt="" src={ImgV100}/>
                            <aside>
                                <h4>{t('accesscontrolsystem.v300_h')}</h4>
                                <span>{t('accesscontrolsystem.v300_t')}</span>
                                <a href={v300} target="_blank" rel="noopener noreferrer" className="vsLink">Read more</a>
                            </aside>
                        </li></div>
                        <div className="v2000"><li><img alt="" src={ImgV100}/>
                            <aside>
                                <h4>{t('accesscontrolsystem.v2000_h')}</h4>
                                <span>{t('accesscontrolsystem.v2000_t')}</span>
                                <a href={v2000} target="_blank" rel="noopener noreferrer" className="vsLink">Read more</a>
                            </aside>
                        </li></div>
                        <div className="vIP2r"><li><img alt="" src={ImgVIP2R}/>
                            <aside>
                                <h4>{t('accesscontrolsystem.v-ip2-r_h')}</h4>
                                <span>{t('accesscontrolsystem.v-ip2-r_t')}</span>
                                <a href={IP2_RW} target="_blank" rel="noopener noreferrer" className="vsLink">Read more</a>
                            </aside>
                        </li></div>
                        <div className="vIP2w"><li><img alt="" src={ImgVIP2W}/>
                            <aside>
                                <h4>{t('accesscontrolsystem.v-ip2-w_h')}</h4>
                                <span>{t('accesscontrolsystem.v-ip2-w_t')}</span>
                                <a href={IP2_W} target="_blank" rel="noopener noreferrer" className="vsLink">Read more</a>
                            </aside>
                        </li></div>

                    </div>
                </div>
            </div>
        );
    }
}

export class VideoSurveillance extends Component {
    render() {
        const { t } = this.props;

        return (
            <div className="vds">

                <Info pageName="videosurveillance" pdfs={[]}/>

                <div className="wrapper">

                    <div className="longBody">

                        <div className="videoMng"><li><img alt="" src={ImgVideo}/>
                            <aside>
                                <h4>{t('videosurveillance.vms_h')}</h4>
                                <span>{t('videosurveillance.vms_t')}</span>
                                <a href="https://www.ksenos.com" target="_blank" rel="noopener noreferrer" className="vsLink">VMS software</a>
                            </aside>
                        </li></div>

                        <div className="cameraMng"><li><img alt="" src={ImgCamera}/>
                            <aside>
                                <h4>{t('videosurveillance.cam_h')}</h4>
                                <span>{t('videosurveillance.cam_t')}</span>
                                <NavLink to={`/products/security/components?origin=camera`} className="vsLink">Selection</NavLink>
                            </aside>
                        </li></div>

                    </div>
                </div>

            </div>
        );
    }
}

