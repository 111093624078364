import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

import Info from "./Info";

import v200 from "../pdf/security/surveillance/Datasheet_LX-SEC-V200.pdf";
import Lonix_System_Catalogue from "../pdf/support/Lonix_System_Catalogue.pdf";
import referencesFIN from "../pdf/solutions/COBA Referenssilista.pdf";
import referencesENG from "../pdf/solutions/COBA References.pdf";
import IBMS from "../pdf/solutions/Lonix_IBMS_specification.pdf";
import case_psu from "../pdf/news/CaseStudy_PSU.pdf";
import case_sbi from "../pdf/news/CaseStudy_SBI.pdf";
import case_mazaya from "../pdf/news/CaseStudy_Mazaya.pdf";
import case_opsut from "../pdf/news/CaseStudy_Opsut.pdf";
import case_pti from "../pdf/news/CaseStudy_PTI.pdf";
import case_kamchia from "../pdf/news/CaseStudy_Kamchia.pdf";
import case_hilton from "../pdf/news/CaseStudy_HiltonDeadSea.pdf";
import case_hotel from "../pdf/solutions/CaseStudy_hotel brochure_digital.pdf";
import pdf_remote from "../pdf/solutions/Lonix_Remote_Monitoring.pdf";
import pdf_hotels from "../pdf/solutions/Lonix_Hotels.pdf";

import hotel from "../stylesheets/images/koti2.jpg";
import office from "../stylesheets/images/buildings.jpg";
import station from "../stylesheets/images/basestation.jpg";
import center from "../stylesheets/images/dorra_web.jpg";


class Solutions extends Component {
    render() {
        const { t, i18n } = this.props;
        let reference = i18n.language === 'en' ? referencesENG : referencesFIN;

        return (
            <div className="solutions">

                <Info pageName="solutions" pdfs={[Lonix_System_Catalogue, reference, IBMS]} />

                <div className="wrapper">

                    <div className="longBody">

                        <div className="business_premises"><li><img alt="" src={office}/>
                            <aside>
                                <h4>{t('solutions.body.h_1')}</h4>
                                <span>{t('solutions.body.t_1')}</span>
                                <div>  <a href={case_mazaya} target="_blank" rel="noopener noreferrer" className="caseLink">Case Study: IBMS at the Prestigious Complex of Three Business Towers</a></div>
                                <div> <a href={case_psu} target="_blank" rel="noopener noreferrer" className="caseLink">Case Study: Integrated Security Systems at University Campus</a></div>
                                <div> <a href={IBMS} target="_blank" rel="noopener noreferrer" className="caseLink">Technical Specification - Integrated BMS</a> [pdf] </div>
                            </aside>
                        </li></div>

                        <div className="hotels"><li><img alt="" src={hotel}/>
                            <aside>
                                <h4>{t('solutions.body.h_2')}</h4>
                                <span>{t('solutions.body.t_2')}</span>
                                <div> <a href={case_hotel} target="_blank" rel="noopener noreferrer" className="caseLink"><strong>Lonix Solutions for Hotels</strong></a> [pdf] </div>
                                <div style={{display: 'none'}}> <a href={pdf_hotels} target="_blank" rel="noopener noreferrer" className="caseLink">Lonix Solution for Guest Rooms</a> [pdf] </div>
                                <div> <a href={case_sbi} target="_blank" rel="noopener noreferrer" className="caseLink">Case Study: IBMS in Luxurious Holiday Resort</a></div>
                                <div> <a href={case_pti} target="_blank" rel="noopener noreferrer" className="caseLink">Case Study: IBMS at the Premier Inn Hotel</a></div>
                                <div> <a href={case_hilton} target="_blank" rel="noopener noreferrer" className="caseLink">Case Study: Lonix IBMS at the Hilton Dead Sea Resort</a></div>
                            </aside>
                        </li></div>
                        <div className="data-centers"><li><img alt="" src={center}/>
                            <aside>
                                <h4>{t('solutions.body.h_3_0')}</h4>
                                <span>{t('solutions.body.t_3_0')}</span>
                                <div>  <a href={case_kamchia} target="_blank" rel="noopener noreferrer" className="caseLink">Case Study: Lonix Systems at Multifunctional Sports Complex</a></div>
                            </aside>
                        </li></div>
                        <div className="data-centers" style={{display: 'none'}}><li><img alt="" src={center}/>
                            <aside>
                                <h4>{t('solutions.body.h_3')}</h4>
                                <span>{t('solutions.body.t_3')}</span>
                                <div>  <a href={v200} target="_blank" rel="noopener noreferrer" className="caseLink">{t('solutions.body.a_2')}</a></div>
                            </aside>
                        </li></div>
                        <div className="remote_monitoring"><li><img alt="" src={station}/>
                            <aside>
                                <h4>{t('solutions.body.h_4')}</h4>
                                <span>{t('solutions.body.t_4')}</span>
                                <div>  <a href={case_opsut} target="_blank" rel="noopener noreferrer" className="caseLink">Case Study: Remote Control and Monitoring in the Nationwide Fiber Network</a></div>
                                <div>  <a href={pdf_remote} target="_blank" rel="noopener noreferrer" className="caseLink">Solution in brief</a> [pdf] </div>
                            </aside>
                        </li></div>

                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Solutions);

