import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";

import Info from "../Info";

class Products extends Component {
    render() {
        const { t, match } = this.props;
        return (
            <div className="products">

                <Info pageName="products" pdfs={[]}/>

                <div className="wrapper" >

                    <div className="longBody">

                        <div className="pSoftware"><li><NavLink to={`${match.url}/software`}/>
                            <aside>
                                <h4>{t('header.software')}</h4>
                                <span>{t('products.body.software')}</span>
                                <NavLink to={`${match.url}/software`} className="vsLink">{t('products.body.link')}</NavLink>
                            </aside>
                        </li></div>

                        <div className="pMetering"><li><NavLink to={`${match.url}/metering`}/>
                            <aside>
                                <h4>{t('header.metering')}</h4>
                                <span>{t('products.body.metering')}</span>
                                <NavLink to={`${match.url}/metering`} className="vsLink">{t('products.body.link')}</NavLink>
                            </aside>
                        </li></div>

                        <div className="pAutomation"><li><NavLink to={`${match.url}/automation`}/>
                            <aside>
                                <h4>{t('header.automation')}</h4>
                                <span>{t('products.body.automation')}</span>
                                <NavLink to={`${match.url}/automation`} className="vsLink">{t('products.body.link')}</NavLink>
                            </aside>
                        </li></div>

                        <div className="pSecurity"><li><NavLink to={`${match.url}/security`}/>
                            <aside>
                                <h4>{t('header.security')}</h4>
                                <span>{t('products.body.security')}</span>
                                <NavLink to={`${match.url}/security`} className="vsLink">{t('products.body.link')}</NavLink>
                            </aside>
                        </li></div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(Products);
