import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

import Info from "../Info";

import System_Catalogue from "../../pdf/support/Lonix_System_Catalogue.pdf";

import sw_client from "../../stylesheets/images/sw_coba_client.jpg";
import sw_web from "../../stylesheets/images/webui_jousenpuisto001.jpg";
import sw_coba from "../../stylesheets/images/sw_server.jpg";

import coba_server from "../../pdf/software/Datasheet_COBA-SRV_long 190407a.pdf";
import coba_webui from "../../pdf/software/Datasheet_LX-COBA-WebUI.pdf";

class Software extends Component {

    render() {
        const { t } = this.props;

        return (
            <div className="software">

                <Info pageName="software" pdfs={[System_Catalogue]}/>

                <div className="wrapper" >

                    <div className="longBody">

                        <div className="serverSoftware"><li><img alt="" src={sw_coba}/>
                            <aside>
                                <h4>{t('software.body.h_1')}</h4>
                                <span>{t('software.body.t_1')}</span>
                                <a href={coba_server} target="_blank" rel="noopener noreferrer" className="vsLink">Datasheet</a>
                            </aside>
                        </li></div>

                        <div className="clientSoftware"><li><img alt="" src={sw_client}/>
                            <aside>
                                <h4>{t('software.body.h_2')}</h4>
                                <span>{t('software.body.t_2')}</span>
                            </aside>
                        </li></div>

                        <div className="webuiSoftware"><li><img alt="" src={sw_web}/>
                            <aside>
                                <h4>{t('software.body.h_3')}</h4>
                                <span>{t('software.body.t_3')}</span>
                                <a href={coba_webui} target="_blank" rel="noopener noreferrer" className="vsLink">Datasheet</a>
                            </aside>
                        </li></div>


                    </div>
                </div>

            </div>
        );
    }
}
export default withTranslation()(Software);
