import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import Logo from "./stylesheets/images/lonix_blue_285x40.png";

import case_mazaya from "./pdf/news/CaseStudy_Mazaya.pdf";
import case_kamchia from "./pdf/news/CaseStudy_Kamchia.pdf";
import case_deadsea from "./pdf/news/CaseStudy_HiltonDeadSea.pdf";
import case_opsut from "./pdf/news/CaseStudy_Opsut.pdf";
import case_psu from "./pdf/news/CaseStudy_PSU.pdf";
import case_pti from "./pdf/news/CaseStudy_PTI.pdf";

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            t: this.props.t,
            products: null,
            solutions: null,
            secondary: null,
        };
    }

    render() {
        let { t } = this.props;

        const listOne = ['/products/software', '/products/metering', '/products/automation', '/products/security/components',
            '/products/security/accesscontrolsystem', '/products/security/videosurveillance'];
        const listTwoX = ['IBMS at the Prestigious Complex of Three Business Towers', 'Remote Control and Monitoring in the Nationwide Fiber Network',
        'IBMS at the Premier Inn Hotel', 'Lonix Systems at Multifunctional Sports Complex', 'Lonix IBMS at the Hilton Dead Sea Resort',
        'Integrated Security Systems at University Campus'];
        const listTwoPdfX = [case_mazaya, case_opsut, case_pti, case_kamchia, case_deadsea, case_psu];
        const listThree = ['home', 'news', 'support', 'contact'];

        let listOneParsed = listOne.map((item, i) => {
            let name = item.split('/').pop();
            return(
                <li key={i}>
                    <Link to={item}>
                        {t('header.normalCaps.'+name)}
                    </Link>
                </li>
            )
        });

        let listTwoParsed = listTwoX.map((item, i) => {
            return(
                <li key={i}>
                    <a href={listTwoPdfX[i]} target="_blank" rel="noopener noreferrer" className="vsLink">{item} [PDF]</a>
                </li>
            )
        });

        let listThreeParsed = listThree.map((item, i) => {
            let name = item === 'home' ? '' : item;
            return(
                <li key={i}>
                    <Link to={"/"+name}>{t('header.'+item)}</Link>
                </li>
            )
        });

        return (
            <div className="footerBody">
                <div className="first">
                    <h4>{t('header.products')}</h4>
                    <ul>{listOneParsed}</ul>
                </div>
                <div className="second">
                    <h4>CASE STUDIES</h4>
                    <ul>{listTwoParsed}</ul>
                </div>
                <div className="third">
                    <ul>{listThreeParsed}</ul>
                </div>

                <div className="fourth">
                    <a href={"#/"}><img src={Logo} alt="" style={{filter: 'brightness(0) invert(0.85)', width: '60%'}}/></a>
                    <div>{t('contact.body.p_1_0')}</div>
                </div>

                <div className="siteCreator">React-powered by @masudd @GitHub</div>
            </div>
        );
    }
}
export default withTranslation()(Footer);
