
export const resFin = {
    'general': {
        'cookie': 'Tämä verkkosivusto käyttää evästeitä käyttökokemuksen parantamiseksi.',
        'cookie_but': 'Ymmärrän',
        'sheet': 'tiedote',
    },
    'header': {
        'home2': 'LONIX RATKAISUT',
        'home': 'ETUSIVU',
        'company': 'YRITYS',
        'news': 'UUTISET',
        'products': 'TUOTTEET',
        'solutions': 'RATKAISUT',
        'automation': 'AUTOMAATIO',
        'metering': 'KULUTUSMITTAUKSET',
        'support': 'TUKI',
        'contact': 'YHTEYSTIEDOT',
        'security': 'TURVALLISUUS',
        'software': 'OHJELMISTOT',
        'notfound': 'SIVUA EI LÖYDY',

        'normalCaps': {
            'software': 'Ohjelmistot',
            'automation': 'Automaatio',
            'metering': 'Kulutusmittaukset',
            'components': 'Turvatuotteet',
            'accesscontrolsystem': 'Kulunvalvontajärjestelmä',
            'videosurveillance': 'Videovalvonta',
        },

        'components': 'TURVATUOTTEET',
        'accesscontrolsystem': 'KULUNVALVONTA',
        'videosurveillance': 'VIDEOVALVONTA',

        'resources': 'Dokumentit',

        'offices': 'Toimistot & liikerakennukset',
        'hotels': 'Hotellit',
        'centers': 'Tietokeskukset & tuotantolaitokset',
        'remotes': 'Etävalvonta & -ohjaus',

    },
    'software': {
        'info': {
            'h_1': 'Lonix ohjelmistot',
            'h_1_1': 'Rakennuskäyttöjärjestelmänalusta',
            't_1': 'Lonix-ohjelmistotuotteet perustuvat standardipohjaisiin COBA BOS Framework -määritelmiin. Yhteensopiviin järjestelmiin sisältyy erilaisia ' +
                'järjestelmiä ja laitteita, jotka liittyvät täysipainoiseen rakennusautomaatio- ja turvajärjestelmään.',
        },
        'body': {
            'h_1': 'COBA Palvelinohjelmisto',
            't_1': 'COBA palvelinohjelmisto on ohjelmistoalusta rakennusjärjestelmien integrointiin ja yhteentoimivuuteen. Normaalin SCADA: n lisäksi se tarjoaa ' +
                'läpinäkyvyyttä rakennuksen hallinta- ja turvajärjestelmille yhtenäisen rajapinnan kautta. COBA laajenee ohjaimien kautta moniin nykyisiin ja ' +
                'tuleviin järjestelmiin ja protokolliin, mukaan lukien mm. BACnet, LonWorks, Modbus, M-BUS, SNMP ja muut.',
            'h_2': 'COBA Asiakasohjelmisto',
            't_2': 'COBA Client on ammattimainen valvontasovellus kaikille COBA-järjestelmään kytketyille järjestelmille, rakennusautomaatioista ja huoneohjauksista ' +
                'hätävalaistukseen ja vaativiin turvajärjestelmiin. Samaa asiakasta voidaan käyttää etäyhteyteen useisiin sivustoihin. COBA Client on täydellinen ' +
                'valinta vaativille ammattimaisille seurantasovellusten käyttäjille.',
            'h_3': 'COBA WebUI Sovellus',
            't_3': 'COBA WebUI on käyttäjäystävällinen laajennus ammattimaisille valvontasovelluksille. Kaikkiin COBA-liitettyihin rakennusten hallinta- ja ' +
                'turvajärjestelmiin on helppo pääsy tavallisella selaimella. COBA WebUI tarjoaa merkittäviä etuja joustavuuden ja helpon käytön suhteen. ' +
                'Käyttöliittymä skaalautuu erikokoisiin laitteisiin mobiilista tabletteihin.',
        }
    },
    'home': {
        'info': {
            'h_1':  'Lonixilta luotetut ratkaisut kiinteistösi parhaaksi',
            't_1':  '',
        },
        'body': {
            'h_1':  'Lonix ratkaisuja on toimitettu ympäri maailman vuodesta 1996 lähtien.\n',
            't_1':  'Lonix tuoteperhe kattaa automaatio-, mittaus- ja turvajärjestelmät, jotka integroituvat automaattisesti COBA Building Operating System (BOS) IoT alustan avulla.\n\n' +
                    'COBA BOS on luotettava ja avoin IoT alusta kiinteistöjen kaikkien järjestelmien liittämiseen turvallisesti ja kustannustehokkaasti nykyisiin ja tuleviin IoT palveluihin. ' +
                    'COBA BOS alustaan on integroitu lukuisten valmistajien rakennusautomaatio-, kulutusmittaus-, huoneistoautomaatio-, hotellihuoneautomaatio-, kulunvalvonta ja rikosilmoitus-, ' +
                    'paloilmoitin- ja turvavalojärjestelmiä tuhansissa referenssikohteissa. COBA alusta tuottaa merkittävää lisäarvoa kaikille liitetyille järjestelmille nostamalla niiden ' +
                    'käytettävyyden uudelle tasolle turvallisesti.\n\n' +
                    'Lonix ratkaisut soveltuvat kaikentyyppisiin kiinteistöihin. Ratkaisuissa on käytetty alusta alkaen avoimia standardirajapintoja, mikä on mahdollistanut helpon päivitettävyyden, ' +
                    'edulliset ylläpitokustannukset ja pitkän elinkaaren kaikille järjestelmille. Yli 20 vuotta sitten toimitettuja järjestelmiä on edelleen runsaasti käytössä. Lonix ratkaisu on ' +
                    'turvallinen ja taloudellisesti järkevä investointi.',
            't_x':  'Lonix on Coba International Oy:n tuotemerkki',
            't_1x':  'COBA International Oy toimittaa markkinoiden parhaat ratkaisut, joita on toimitettu ympäri maailman jo vuodesta 1996 lähtien. ' +
                    'Osana järjestelmiä käytämme luotettavia Lonix komponentteja. COBA International on täysin kotimainen yritys. ' +
                    'Perustamisesta lähtien olemme kehittäneet älykkäitä, sekä energiatehokkaita ratkaisuja globaaleille markkinoille.\n\n' +
                    'COBA Building Operating System (BOS) on luotettava IoT alusta kiinteistöjen kaikkien järjestelmien liittämiseen turvallisesti ' +
                    'ja kustannustehokkaasti nykyisiin ja tuleviin IoT palveluihin. COBA BOS alustaan on integroitu lukuisten valmistajien ' +
                    'rakennusautomaatio-, kulutusmittaus-, huoneistoautomaatio-, hotellihuoneautomaatio-, kulunvalvonta ja rikosilmoitus- ' +
                    'paloilmoitin- ja turvavalojärjestelmiä tuhansissa referenssikohteissa.\n\nCOBA alusta tuottaa merkittävää lisäarvoa kaikille ' +
                    'liitetyille järjestelmille nostamalla niiden käytettävyyden uudelle tasolle turvallisesti.\n\nHenkilöstömme koostuu joukosta todellisia ' +
                    'alan ammattilaisia. Pystymme toteuttamaan ratkaisut kaikentyyppisiin kiinteistöihin.',
            'h_2':  'Lonix tarjoaa parhaat ratkaisut\n',
            't_2':  'Lonixilla on kattava, monipuolinen asiantuntemus älykkäiden, integroitujen automaatio- ja turvajärjestelmien innovatiivisista ratkaisuista hyvin monenlaisille ' +
                    'rakennuksille. Olemme johtavia asiantuntijoita edistyneiden automaatioiden ja hallintalaitteiden lisäksi myös integroitujen, älykkäiden järjestelmien suunnittelussa ' +
                    'energiatehokkaiden rakennusten mahdollistamiseksi. Tunnemme eri rakennusten tarpeet ja osaamme hyötyä eturintamassa olevista tekniikoista.\n\n' +
                    'Lonixia pidetään yhtenä alan johtavista teknologian, älykkäiden sovellusten ja modernin suunnittelun aloista. Pääkonttori sijaitsee Helsingissä, ' +
                    'Lonix on ollut mukana menestyksekkäästi toteuttamassa integroituja automaatioratkaisuja eri maissa ympäri maailmaa. Kumppanuusverkostomme kattaa tänään ' +
                    'Pohjois-Euroopan, Itä-Euroopan, Venäjän, Etelä-Amerikan ja Keski-Amerikan, Lähi-idän, Itä-Afrikan ja Kaukoidän.\n\n' +
                    'Lonix-ohjaimet on valmistettu EU: ssa ISO 9001 ja ISO 14001 -standardien mukaisesti. Ohjaimet täyttävät Venäjän viranomaisten vaatimat palotodistukset ja GOST R -sertifikaatit.',
        },
    },
    'solutions': {
        'info': {
            'h_1':  '',
            't_1':  'Huippuluokan Lonix-järjestelmä tarjoaa sinulle täydellisen ratkaisun kestävään kehitykseen ja vihreisiin rakennuksiin. Järjestelmän ' +
                    'skaalautuvuus mahdollistaa tehokkaat ratkaisut kaikenlaisiin rakennuksiin.'
        },
        'body': {
            'h_1':  'Toimistot & liikerakennukset',
            't_1':  'Ratkaisussa yhdistyvät automaatio- ja tietoturva-alan edistyksellisimmät ja avoimimmat tekniikat täydelliseksi ratkaisuksi älykkäisiin ' +
                    'rakennuksiin. Ratkaisu takaa yrityksellesi tehokkaan mutta taloudellisen ympäristön',
            'a_1':  'Case Study',
            'a_2':  'Lue lisää',
            'h_2':  'Hotellit',
            't_2':  'Skaalautuva Lonix-ratkaisu soveltuu sekä korkealuokkaisimmille ja budjetti-tason hotelleille. Ratkaisu tarjoaa helppokäyttöisyyttä ja mukavuutta ' +
                    'yhdistettynä vähentyneeseen energiankulutukseen. Saumaton integrointi mahdollistaa kaikkien järjestelmien automaattisen säätämisen ja helpon valvonnan. ' +
                    'Ekologiset vaikutukset ovat merkittävät - täydellinen ratkaisu terveellisemmälle planeetalle.',
            'h_3_0':  'Urheilukeskukset ja stadionit',
            't_3_0':  'Integroidut Lonix-rakennusautomaatio- ja turvajärjestelmät sopivat hyvin erityyppisiin urheilukeskuksiin.',

            'h_3':  'Tietokeskukset & tuotantolaitokset',
            't_3':  '',
            'h_4':  'Kaukovalvonta & -ohjaus',
            't_4':  'Integroitu etävalvonta- ja -ohjausratkaisu on suunniteltu erityisesti verkottuneiden etäasemien, kuten matkaviestinverkkojen ' +
                    'tukiasemien tai kuituverkkojen valvontasolmujen, ohjaustarpeisiin. Tuhannet etäpaikat voidaan helposti kytkeä yhteen keskiseen ' +
                    'käyttöliittymään'
        }
    },
    'news': {
        'info': {
            'h_1': 'Viimeisimmät uutiset Lonixilta',
            't_1': 'The cutting-edge Lonix Automation, integrating with Lonix Security, creates the perfect'
        },
        'body': {
            't_1': 'Lehdistötiedoite',
            't_2': 'Tuoteuutinen',
            't_3': 'Päiväys',
            't_4': 'Allekirjoittanut ',
            't_link': 'Linkki',
        },
        'articles': {
            'n_1_h': 'Lonix sivusto on muuttanut uuteen ulkoasuun',
            'n_1_t': 'Sivusto skaalautuu hyvin myös uudemmilla laitteilla.'
        }
    },
    'support': {
        'info': {
            'h_1': 'Tekniset tiedot - Käyttöohjeet - Koulutukset',
        },
        'body': {
            't_1': 'Tämä on materiaalien, esimerkkien ja käsikirjojen pääkirjasto. Lonixilla on myös koulutusohjelmien valikoima koulutuskeskuksissamme Helsingissä ' +
                '/ Dubaissa tai asiakaskeskuksissa.\n\nJos tarvitset lisää neuvoja, älä epäröi ottaa yhteyttä meihin henkilökohtaista tukea varten.',

            'h_1': 'TIEDOT',
            'h_1_1': 'TEKNISET TIEDOT',
            'h_2': 'MANUAALIT',
            'h_2_1': 'AUTOMATION CONTROLLERS',
            'h_2_2': 'SECURITY CONTROLLERS',
            'h_2_3': 'SOFTWARE',
            'h_3': 'TRAINING',
            'h_3_1': 'SYSTEM OVERVIEW',
            'h_4': 'SERVICES',
            't_4': 'Toivomme, että otatte meihin yhteyttä saadaksenne lisätietoja tai tarjouksia: sales [at] lonix.com',
        }
    },
    'products': {
        'info': {
            'h_1':  'Luotetut ratkaisut kiinteistösi parhaaksi',
            'h_1_1':  'Luotetut ratkaisut\nkiinteistösi parhaaksi',
            't_1':  'Lonix tarjoaa ydinkomponentit älykkääseen rakennusautomaatioon, älykkään turvallisuuden ja integroituihin kiinteistöjen ' +
                    'hallintajärjestelmiin. Tuotevalikoima sisältää älykkäiden hallintalaitteiden ja integroitujen järjestelmien lisäksi tehokkaan ' +
                    'ylläpidon ja palveluiden avaintekijät.',
        },
        'body': {
            'link': 'Sivulle',
            'automation': 'Älykkään BMS: n eturintamassa olevat tuotteet ovat ydinosaamistamme. FLEX-sarjan ohjaimet, joissa on kattava ohjaus- ja ' +
                        'valvontaominaisuus, TOUCH-sarja moderneille, mutta hämmästyttävän helpoille kosketuskäyttöliittymille, kattava rakennuksen ' +
                        'käyttöjärjestelmäohjelmisto yhteensopivuutta varten - kaikki edustaa erinomaista laatua ja monipuolisia ominaisuuksia.',
            'security': 'Lonix Security tarjoaa integroidun ratkaisun erinomaiseen tietoturvaan. IP-ohjatut turvajärjestelmät perustuvat todistettuihin ' +
                        'laitteistoihin ja maailmanluokan ohjelmistoihin, integroituna sujuvasti automaatio- ja rakennushallintajärjestelmiin.',
            'metering': 'Lonix Vedenmittaus on varmatoiminen etäluettava huoneistokohtainen vedenmittausjärjestelmä, joka pohjautuu standardiin ' +
                        'väyläprotokollaan ja yleisesti käytettyyn mittariteknologiaan. Voit valita kohteesi mukaan langattomat tai langalliset väyläpohjaiset ' +
                        'M-Bus-mittarit tai perinteisen varmatoimiset impulssimittarit. ',
            'software': 'Lonix-ohjelmistotuotteet perustuvat Lonix COBA Building -käyttöjärjestelmän alustaan. COBA sisältää rajapinnat yleisimpiin protokolliin, ' +
                        'mukaan lukien BACnet, LonWorks, Modbus, M-Bus, SNMP, OPC ja muut. Lonix-järjestelmä tukee BACnet / IP-tietoliikenneprotokollaa DDC-paneelien ja ' +
                        'Control Server -ohjelmiston välillä.'
        },
    },
    'automation': {
        'info': {
            'h_1':  'Lonix kiinteistöautomaatiojärjestelmät tarjoavat luotettavan kokonaisratkaisun kaikkiin tarpeisiin.',
            'h_1_1':'Lonix kiinteistöautomaatio-\njärjestelmät tarjoavat luotettavan\nkokonaisratkaisun kaikkiin tarpeisiin',
            't_1':  '',
            },
        'body': {
            't_1':  'Automaatio-, mittaus-, valvonta- ja turvajärjestelmät integroituvat joustavasti yhteen ja samaan ' +
                    'valvomoon, johon tarjoamme käyttäjille suojatun etäkäyttöpalvelun.\n\n' +
                    'Valikoimamme ohjausmoduulit, käyttöliittymät, ohjelmistot rajapintoineen, anturit, toimilaitteet ' +
                    'ja muut kenttälaitteet auttavat valitsemaan kaikki tarvittavat komponentit ratkaisuunne.'
        },
        'h_1': 'AUTOMATION CONTROLLERS & PANELS',
        'h_1_1': 'FLEX SERIES CONTROLLERS',
        'h_1_2': 'TOUCH SERIES PANELS',
        'h_1_3': 'CONNEX SERIES INTERFACES',
        'h_1_4': 'LEGACY APPLICATIONS',
        'h_2': 'FCU THERMOSTATS',
        'h_2_1': 'MODULAR THERMOSTATS',
        'h_3': 'ROOM SENSORS',
        'h_3_1': 'SWITCHES AND ACCESSORIES',
        'h_3_2': 'MODERN DESIGN',
        'h_3_3': 'FUNCTIONAL DESIGN',
        'h_4': 'BMS SENSORS',
        'h_4_1': 'TEMPERATURE',
        'h_4_2': 'HUMIDITY',
        'h_4_3': 'CARBONDIOXIDE (CO2)',
        'h_4_4': 'CARBONMONOXIDE (CO)',
        'h_4_5': 'PRESSURE',
        'h_4_6': 'ILLUMINATION',
        'h_4_7': 'AIR FLOW',
        'h_4_8': 'LIQUID FLOW',
        'h_4_9': 'LIQUID LEVEL',
        'h_4_10': 'WATER LEAKAGE',
        'h_4_11': 'SMOKE',
        'h_4_12': 'OCCUPANCY'
    },
    'metering': {
        'info': {
            'h_1':  'Huoneistokohtainen veden- ja energianmittaus - Langallisena tai langattomana',
            'h_1_1': 'Huoneistokohtainen\nveden- ja energianmittaus\n- Langallisena tai langattomana',
            't_1':  'Lonix Solutions vedenmittaus on varmatoiminen etäluettava huoneistokohtainen mittausjärjestelmä, joka pohjautuu standardiin ' +
                    'Mbus väyläprotokollaan ja yleisesti käytettyyn mittariteknologiaan. Voit valita kohteesi mukaan langattomat tai langalliset ' +
                    'väyläpohjaiset Mbus mittarit. Langallisten Mbus mittareiden elinkaari on jopa ' +
                    '25 vuotta ja takuuaika mittareiden osalta on jopa viisi vuotta.\n\n' +
                    'Järjestelmä lähettää kulutuslukemat automaattisesti internetin välityksellä etäluentapalveluun, jolloin kulutustiedot on helppo ' +
                    'lukea tavallista Internet-selainta käyttäen. Tiedot voidaan siirtää joustavasti yleisimpiin laskutusohjelmiin.',
        },
        'body': {
            'h_1':  'Etäluettavat Lonix veden- ja energianmittaukset',
            't_1':  'Lonix kulutusmittausjärjestelmä sisältää vesi- ja/tai energiamittarit, keskusyksikön sekä valmiuden etäluentaan Internet-selainta käyttäen. ' +
                    'Kulutustiedot kerätään keskusyksikköön, josta ne lähetetään automaattisesti etäluentapalveluun. Etäluentapalvelua tarjotaan ' +
                    'isännöitsijöiden ja asukkaiden käyttöön. Lukema voidaan aina tarvittaessa tarkistaa myös vesi- tai energiamittarin omasta näytöstä. ' +
                    'Lonix veden- ja energianmittaus integroituu helposti automaatiojärjestelmäämme.',
            'h_2': 'Ota yhteyttä!',
            't_2': 'Puh. +358 9 349 9853\ninfo [at] lonix.com / vedenmittaus [at] lonix.com ',
            'h_3': 'VEDENMITTAUKSEN LATAUKSET',
        },
        'h_3': 'ESITTEET:',
        't_3': '',
        'h_4': 'TYÖSELITYSMALLIT:',
        't_4': '',
        'h_5': 'ASENNUSOHJEET:',
        't_5': '',
        'h_6': 'MUUT AINEISTOT:',
        't_6': ''

    },
    'security': {
        'info': {
            'h_1':  'Lonix Security Management System on skaalautuva ratkaisu kaikentyyppisiin rakennuksiin.',
            't_1':  'Lonix Security on integroitu IP-pohjainen ratkaisu korkeatasoiseen turvallisuuteen. Ratkaisu ' +
                    'mahdollistaa sujuvan integroinnin automaatio- ja talonhallintajärjestelmiin.'
        },
    },
    'components': {
        'info': {
            'h_1': 'Täydellinen ratkaisu erinomaiseen turvallisuuteen',
            'h_1_1': 'Täydellinen ratkaisu\nerinomaiseen turvallisuuteen',
            't_1':  'Valikoima yhteensopivia kameroita ja muita turvallisuuskomponentteja auttaa sinua toteuttamaan korkealaatuiset ' +
                    'tietoturvaratkaisut, jotka täyttävät kaikki vaatimukset',
        },
        'h_1': 'ACCESS CONTROL AND INTRUDER ALARM SYSTEM',
        'h_1_1': 'V-SERIES CONTROLLERS',
        'h_2': 'CAMERAS',
        'h_2_1': 'INDOOR DOME CAMERAS',
        'h_2_2': 'FISH EYE PANORAMA CAMERAS',
        'h_2_3': 'BOX CAMERAS',
        'h_2_4': 'VANDAL DOME CAMERAS',
        'h_2_5': 'BULLET CAMERAS',
        'h_2_6': 'PTZ CAMERAS',
        'h_3': 'ACCESSORIES',
        'h_3_1': 'CAMERA ACCESSORIES',
    },
    'accesscontrolsystem': {
        'info': {
            'h_1':  'Kulunvalvontajärjestelmä ja murtosuojaus',
            'h_1_1':  'Kulunvalvontajärjestelmä\nja murtosuojaus',
            't_1':  'Lonix V-sarjan ohjaimet tarjoavat täydellisen ja täysin varustellun laitteiston / laiteohjelmiston ' +
                    'infrastruktuurin pääsynvalvonta- ja tunkeutumishälytysjärjestelmiin. Älyverkko-ohjaimet ja liitäntäpaneelit ' +
                    'ohjaavat ja valvovat pääsynvalvonta- ja tunkeutumishälytysjärjestelmää.',
        },
        'v1000_h':  'V1000 Verkko-ohjain',
        'v1000_t':  'V1000 verkko-ohjain sisältää täyden älykkyyden järjestelmän toiminnallisuudelle, jolloin käyttöliittymän ' +
                    'käsittely on jopa 32 liitäntäpaneelia. Verkonohjain sisältää 32-bittisen prosessorin, joka käyttää ' +
                    'Linux-käyttöjärjestelmää. Verkko-ohjain tekee kaikki online-ovipäätteet, sisääntulon seurannan ja ulostulon ' +
                    'ohjauksen liitetyille liitäntäpaneeleille.',
        'v100_h':   'V100-lukulaitteen käyttöpaneeli',
        'v100_t':   'V100 Reader -liitäntäpaneeli on yhteydessä kahteen lukijaan Wiegand-liittymän kautta.',
        'v200_h':   'V200-lukulaitteen käyttöpaneeli',
        'v200_t':   'V200-tuloliitännän paneeli on kytketty 16 valvottuun tulopiiriin.',
        'v300_h':   'V300-lukulaitteen käyttöpaneeli',
        'v300_t':   'V300-lähtöliitäntäpaneeli yhdistetään 12 releohjattavan laitteen kanssa.',
        'v2000_h':  'V2000-verkon ohjain ja lukijaliittymäpaneeli',
        'v2000_t':  'V2000-verkon ohjaimen ja lukijan käyttöliittymäpaneeli on yhdistelmä TCP / IP-rajapinta palvelinohjausta varten ' +
                    'ja pääsynvalvonnan prosessointi kahdelle lukijalle',
        'v-ip2-r_h': 'V-IP2-R / RW ovenohjain-lukija ja käyttöliittymämoduuli',
        'v-ip2-r_t': 'V-IP2-R / RW on liitäntämoduulin mukana toimitettava yksioveinen verkkoon kytketty pääsyohjain ja kontaktiton iCLASS-lukija.',
        'v-ip2-w_h': 'V-IP2-W yksiovisäädin',
        'v-ip2-w_t': 'V-IP2-W on yhden oven pääsyn ohjauspaneeli, joka mahdollistaa kustannustehokkaan asennuksen ja korkean suorituskyvyn pääsynhallinnan toiminnot.',
    },
    'videosurveillance': {
        'info': {
            'h_1':  'Integroitu turvallisuus ja yhteensopivat kamerat',
            'h_1_1': 'Integroitu turvallisuus ja\nyhteensopivat kamerat',
            't_1':  'Lonix V-sarjan ohjaimet tarjoavat täydellisen ja täysin varustellun laitteiston / laiteohjelmiston ' +
                    'infrastruktuurin pääsynvalvonta- ja tunkeutumishälytysjärjestelmiin. Älyverkko-ohjaimet ja liitäntäpaneelit ' +
                    'ohjaavat ja valvovat pääsynvalvonta- ja tunkeutumishälytysjärjestelmää.'
        },
        'vms_h': 'Videonhallintajärjestelmä',
        'vms_t': 'Innovatiivisilla ominaisuuksilla varustettu VMS-ohjelmisto on täysin integroitu Lonix Security Management ratkaisuun.',
        'cam_h': 'Kamerat',
        'cam_t': 'Kameravalikoimastamme löytyvät korkealaatuiset sisä- ja ulkokamerat eri resoluutioilla.',
    },
    'contact': {
        'info': {
            'h_1': '',
            't_1': '',
        },
        'body': {
            'h3_1': 'LONIX SOLUTIONS / COBA International Oy',
            'p_1_0': 'Läkkisepäntie 4 A7\n' +
                'FI-00620 Helsinki, SUOMI\n' +
                'Puh. +358 9 349 9853\n' +
                'info [at] lonix.com',
            'h3_2': 'MYYNTI',
            'p_2_0': 'Mika Kelo\n' +
                'mika.kelo [at] coba.fi\n' +
                'Puh. 050 3484805',
            'h3_3': 'KOTIMAAN URAKOINTI',
            'p_3_0': 'Läkkisepäntie 4 A 7\n' +
                '00620 Helsinki\n' +
                'FINLAND\n' +
                'Puh. 050 3484805',
            'h3_4': 'HUOLTO JA TEKNINEN TUKI ',
            'p_4_0': 'huolto [at] lonix.com\n' +
                '\n' +
                'Sami Sanchez (huollot ja käyttöönotot)\n' +
                'Puh. 044 399 2157\n' +
                '\n' +
                'Jukka Jussila (ohjelmistot, kehitys ja lisenssit)\n' +
                'Puh. 050 593 6550',
            'h3_5': 'KANSAINVÄLINEN VERKOSTO',
            'p_5_0': 'Lonixilla on edustajia Pohjoismaiden lisäksi Venäjällä, CIS-maissa, Lähi- ja Kaukoidässä,' +
                ' Latinalaisessa Amerikassa sekä Afrikassa. '
        },

    }            
};
