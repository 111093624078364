import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

import Info from "./Info";

import Lonix_IBMS_Specification from "../pdf/support/Lonix_IBMS_Specification.pdf";
import UserManual_Lonix_M10 from "../pdf/support/UserManual_Lonix_M10.pdf";
import M10_connection_diagrams from "../pdf/support/M10_connection_diagrams.pdf";
import U10AC_A_Connections from "../pdf/support/U10AC-A_Connections.pdf";
import U10FCU_A_Connections from "../pdf/support/U10FCU-A_Connections.pdf";
import U10IO_A_Connections from "../pdf/support/U10IO-A_Connections.pdf";
import UserManual_COBA_Editor from "../pdf/support/UserManual_COBA_Editor.pdf";
import UserManual_Lonix_PCT from "../pdf/support/UserManual_Lonix_PCT.pdf";
import UserManual_ServerManager from "../pdf/support/UserManual_COBA_ServerManager.pdf";
import ConfigManual_Security from "../pdf/support/Configuration_Manual_Lonix_Security.pdf";
import Lonix_System_Catalogue from "../pdf/support/Lonix_System_Catalogue.pdf";

import ImgSupport from "../stylesheets/images/support_training.jpg";
import ImgPdf from "../stylesheets/images/ImgPdf.png";

class Support extends Component {
    render() {
        const { t } = this.props;

        return (
            <div className="support">

                <Info pageName="support" pdfs={[Lonix_System_Catalogue, Lonix_IBMS_Specification]}/>

                <div className="longBody" >

                    <div className="withPhoto">
                        <p style={{marginTop: '15px'}}>{t('support.body.t_1')}</p>
                        <img className="ImgSupport" src={ImgSupport} alt="" />
                    </div>

                    <div className="pdf">
                        <h2>{t('support.body.h_1')}</h2>
                        <table>
                            <caption>{t('support.body.h_1_1')}</caption>
                            <tbody>
                            <tr><th>IBMS specification</th><td><span>Integrated BMS system specification</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={Lonix_IBMS_Specification} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="pdf">
                        <h2>{t('support.body.h_2')}</h2>
                        <table>
                            <caption>{t('support.body.h_2_1')}</caption>
                            <tbody>
                            <tr><th>User Manual</th><td><span>Lonix M10 MultiController</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={UserManual_Lonix_M10} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>Connection Diagram</th><td><span>Lonix M10 MultiController</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={M10_connection_diagrams} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>Connection Diagram</th><td><span>Lonix U10-FCU UnitController</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={U10FCU_A_Connections} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>Connection Diagram</th><td><span>Lonix U10-AC UnitController</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={U10AC_A_Connections} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>Connection Diagram</th><td><span>Lonix U10-IO UnitController</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={U10IO_A_Connections} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('support.body.h_2_2')}</caption>
                            <tbody>
                            <tr><th>Configuration Manual</th><td><span>Lonix V-Series Controller</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={ConfigManual_Security} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('support.body.h_2_3')}</caption>
                            <tbody>
                            <tr><th>User Manual</th><td><span>Lonix Project Creation Tool (PCT)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={UserManual_Lonix_PCT} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>User Manual</th><td><span>COBA Editor</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={UserManual_COBA_Editor} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>User Manual</th><td><span>COBA Server Manager</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={UserManual_ServerManager} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="pdf">
                        <h2>{t('support.body.h_3')}</h2>
                        <table>
                            <caption>{t('support.body.h_3_1')}</caption>
                            <tbody>
                            <tr><th>Introduction</th><td><span>System Overview</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../pdf/support/Lecture_System_Overview.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>Courses</th><td><span>Training Program</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../pdf/support/Lonix_Training_Program.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>Lecture</th><td><span>Lonix Automation</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../pdf/support/Lecture_Lonix_Automation.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>Lecture</th><td><span>Lonix Security</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../pdf/support/Lecture_Lonix_Security.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>Lecture</th><td><span>COBA Software Platform</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../pdf/support/Lecture_COBA_Software_Platform.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>Lecture</th><td><span>System Sales - preparing IBMS BoQ</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../pdf/support/Lecture_Guidelines_IBMS_BoQ.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>Lecture</th><td><span>Introduction to HVAC Processes</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../pdf/support/Lecture_HVAC.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>Lecture</th><td><span>Essentials of Project Management</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../pdf/support/Lecture_Project_Mgmt_Essentials.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>Lecture</th><td><span>Lonix PCT Project Creation Tool</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../pdf/support/Lecture_LonixPCT.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="pdf">
                        <h2>{t('support.body.h_4')}</h2>
                        <p>{'\n\n'+t('support.body.t_4')+'\n\n\n'}</p>
                    </div>

                </div>


            </div>
        );
    }
}
export default withTranslation()(Support);
