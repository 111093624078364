import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

import CookieConsent from "react-cookie-consent";

import Info from "./routes/Info";
import homeOffice from "../src/stylesheets/images/lappari.jpg";
import homeRoom from "../src/stylesheets/images/home3.jpg";

class Home extends Component {
    render() {
        const { t } = this.props;

        return (
            <div className="home">

                <Info pageName="home" pdfs={[]}/>

                <div className="longBody">
                    <div className="withPhoto" >
                        <p><strong>{t('home.body.h_1')}</strong>{t('home.body.t_1')}</p>
                        <img src={homeOffice} alt="" />
                    </div>
                    <div className="withPhoto" >
                        <img src={homeRoom} alt="" />
                        <p><strong>{t('home.body.h_2')}</strong>{t('home.body.t_2')}</p>
                    </div>

                </div>

                <CookieConsent location="bottom" buttonText={t('general.cookie_but')} style={{ background: "#000000" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "18px" }} expires={15}>{t('general.cookie')}</CookieConsent>

            </div>
        );
    }
}
export default withTranslation()(Home);
