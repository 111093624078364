
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import { resEng } from "./en";
import { resFin } from "./fi";

//const languageDetector = new LanguageDetector();

const resources = {
    en: {
        translation: resEng
    },
    fi: {
        translation: resFin
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: resources,

        interpolation: {
            escapeValue: false
        },

        useSuspense: false,

        fallbackLng: 'en'
    });

export default i18n;
