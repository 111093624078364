import React, { Component } from "react";
import {
    Route,
    NavLink,
    Link,
    HashRouter,
    Switch,
    withRouter,
} from "react-router-dom";
//import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import Support from "./routes/Support";
import Products from "./routes/products/Products";
import Solutions from "./routes/Solutions";
import Software from "./routes/products/Software";
import Automation from "./routes/products/Automation";
import Security, { SecurityComponents, AccessControlSystem, VideoSurveillance } from "./routes/products/Security";
import Metering from "./routes/products/Metering";
import Contact from "./routes/Contact";
import News from "./routes/News";
import Footer from "./Footer";
import Home from "./Home";

import Logo from "./stylesheets/images/lonix_test_jpg.jpg";
import Logo2 from "./stylesheets/images/logo finals-14.png";

import house_1 from "./stylesheets/images/optimized/gunillantie_3_1680x981.jpg";
import house_2 from "./stylesheets/images/optimized/gunillantie_4_1680x981.jpg";
import house_3 from "./stylesheets/images/optimized/hakunila_2_1680x981.jpg";
import house_4 from "./stylesheets/images/optimized/hakunila_1_1680x981.jpg";
import house_5 from "./stylesheets/images/optimized/niittysilta_low_1680x981.jpg";

class MainRoutes extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
        this.props.history.listen(this.onRouteChange.bind(this));
    }
    onRouteChange(route) {
        if(!navigator.userAgent.match(/Explorer|Edge/i)) {
            document.querySelector('.container').scrollTo({top: 0, left: 0});
        }else{
            document.querySelector('.container').scrollTop = 0;
        }
    }
    render() {
        const { t, i18n, location } = this.props;
        let prodActive = location.pathname === '/products';

        return(
            <div className="content">
                <Route path='/' render={(props) => <Location {...props} t={t} i18n={i18n}/>}/>
                <Switch>
                    <Route exact path='/' component={Home}/>

                    {prodActive && <Route path='/products' component={Products}/>}
                        <Route path='/products/software' component={Software}/>
                        <Route path='/products/metering' component={Metering}/>
                        <Route path='/products/automation' component={Automation}/>
                        <Route exact path='/products/security' component={Security}/>
                            <Route path='/products/security/components' render={(props) => <SecurityComponents {...props} t={t}/>}/>
                            <Route path='/products/security/accesscontrolsystem' render={(props) => <AccessControlSystem {...props} t={t}/>}/>
                            <Route path='/products/security/videosurveillance' render={(props) => <VideoSurveillance {...props} t={t} active={true}/>}/>
                    <Route path='/solutions' component={Solutions}/>

                    <Route path='/news' component={News}/>
                    <Route path='/support' component={Support}/>
                    <Route path="/contact" component={Contact}/>

                    <Route render={(props) => <Notfound {...props} t={t}/>}/>
                </Switch>
            </div>
        );
    }
}
const MainRoutesWithLocation = withRouter(MainRoutes);

class Main extends Component {
    constructor(props) {
        super(props);

        this.createSubNavMenu = this.createSubNavMenu.bind(this);
        this.toggleSmallMenu = this.toggleSmallMenu.bind(this);
        this.scrollTopNav = this.scrollTopNav.bind(this);
        this.langChange = this.langChange.bind(this);
        this.confirmDevice = this.confirmDevice.bind(this);
        this.setSubNavLoc = this.setSubNavLoc.bind(this);

        this.state = {
            hovered: false,
            subNav: null,
            parsedHeaders: null,

            i18n: this.props.i18n,
            t: this.props.t,
        };

        this.resizeInt = null;
    }
    componentDidMount() {
        this.createSubNavMenu(this.state.t);

        window.addEventListener("resize", (e) => {
            e.preventDefault();
            clearTimeout(this.resizeInt);
            this.resizeInt = setTimeout(()=>{this.setSubNavLoc();}, 500)
        });
    }
    render() {
        let t = this.state.t;

        return (
            <HashRouter>
            <div className="container">

                <div className="header" onClick={this.toggleSmallMenu}>
                    <a href={'/'}>
                        <img className="logo" src={Logo} alt=""/>
                        <img className="logo2" src={Logo2} alt=""/>
                    </a>

                    <Hamburger />

                    <ul className="nav" onClick={(e) => this.scrollTopNav(e, "instant")}>

                        <div className="primaryLinks">
                            <li><NavLink exact to="/products" className="pDropDown" onClick={this.toggleSubNav} onMouseOver={this.confirmDevice}>
                                &#9662; {t('header.products')}</NavLink></li>
                            <li><NavLink to="/solutions">{t('header.solutions')}</NavLink></li>
                        </div>

                        <div className="subNavLinks" onMouseLeave={this.toggleSubNav} onClick={this.toggleSubNav}>
                            {this.state.hovered ? this.state.subNav : null}
                        </div>

                        <div className="secondaryLinks">
                            <li><NavLink exact to="/">{t('header.home')}</NavLink></li>
                            <li><NavLink to="/news">{t('header.news')}</NavLink></li>
                            <li><NavLink to="/support">{t('header.support')}</NavLink></li>
                            <li><NavLink to="/contact">{t('header.contact')}</NavLink></li>
                        </div>

                    </ul>

                    <li className="langButtons">
                        <button name="fin" onClick={() => this.langChange('fi')}>FI</button>
                        <button name="eng" onClick={() => this.langChange('en')}>EN</button>
                    </li>

                </div>

                <Style i18n={this.state.i18n} />

                <MainRoutesWithLocation t={t} i18n={this.state.i18n} hovered={this.state.hovered} />

                <div className="footer">
                    <Route path="/" component={Footer}/>
                </div>

                <button className="upScroll" onClick={(e) => {this.scrollTopNav(e, "smooth");}}>
                    <strong>&#94;</strong>
                </button>

            </div>
            </HashRouter>
        );
    }

    confirmDevice = () => {
        if(!navigator.userAgent.match(/Android|webOS|iPhone|iPod/i)){
            this.toggleSubNav();
        }
    };

    toggleSubNav = () => {
        this.setState(prevState => ({
            hovered: !prevState.hovered
        }));
    };

    langChange = (lng) => {
        this.state.i18n.changeLanguage(lng);
        if(this.state.t !== this.props.t){
            this.setState({t: this.props.t});
        }
        this.createSubNavMenu(this.state.t);
        window.location.reload();
    };

    toggleSmallMenu = (e) => {
        let header = document.querySelector('.header');
        if(window.innerWidth <= 900){
            if(header.classList.contains('toggle') === false){
                header.classList.toggle("toggle");
            }else if(header.classList.contains('toggle') === true && e.target.className.indexOf('pDropDown') === -1){
                header.classList.toggle("toggle");
            }
        }
    };

    scrollTopNav = (e, behavior) => {
        let container = document.querySelector('.container');
        if(!navigator.userAgent.match(/Explorer|Edge/i)){
            container.scrollTo({ top: container.scrollTop-1350,  left: 0, behavior: behavior });
        }else{
            container.scrollTop = container.scrollTop-1350;
        }
    };

    createSubNavMenu = (t) => {
        let routes = ['/products/software', '/products/metering', '/products/automation', '/products/security/components', '/products/security/accesscontrolsystem', '/products/security/videosurveillance'];
        let parsedHeaders = routes.map((header, i) => {
            let headerString = header.split('/').pop();
            let description = t(headerString+'.info.h_1_1');
            //let description = desc.slice(0, 30) + '-\n' + desc.slice(30, 60) + '-\n' + desc.slice(60, 90);
            return (
                <li key={i} className="subNavLink">
                    <Link to={routes[i]}>
                        {<div>
                            <span>{t('header.'+headerString)}</span>
                            <span style={{fontSize: '13px', lineHeight: '1.0', color: '#0073AE', marginTop: '5px'}}>
                            {'\n'+description}</span>
                        </div>}
                    </Link>
                </li>
            );
        });

        this.setState({parsedHeaders: parsedHeaders}, this.setSubNavLoc);

    };

    setSubNavLoc = () => {
        let linkLoc = document.querySelector('.pDropDown').getBoundingClientRect();

        let windowWidth = window.innerWidth;
        let x = windowWidth <= 900 ? 0 : linkLoc.x;
        let y = windowWidth <= 900 ? 10 : linkLoc.y+42;

        this.setState({
            subNav: <ul className="subNav" style={{top: y, left: x}}>{this.state.parsedHeaders}</ul>
        });

    };

}
export default withTranslation()(Main);


export class Location extends Component {
    render() {
        let t = this.props.t;
        let loc = this.props.location.pathname;
        let listItems = loc.split("/").filter( (value) => {
            return value.length > 0;
        });
        if(listItems.length < 1){listItems.push('home');}

        let path = "/";
        let finalItems = listItems.map((pages, i) => {
            console.log("pages: ",pages," i: ",i);
            path += pages+"/";
            let pathHref = pages !== 'home' ? path.substring(0, path.length-1) : '/';
            let locName = t('header.'+pages);
            let spanName = i + 1 < listItems.length ? locName.charAt(0).toUpperCase() + locName.slice(1).toLowerCase() : locName.toUpperCase();
            let check404 = spanName.indexOf('HEADER.') !== -1 ? t('header.notfound') : spanName;
            return (
                <li key={i} style={{display: "inline-block"}}>
                <a className="pathLink" href={pathHref}>{<span>{check404}</span>}</a>&nbsp;/&nbsp;
                </li>
            );

        });
        return (
            <div className="pathLocation"><ul>{finalItems}</ul></div>
        );
    }
}

class Style extends Component {
    constructor(props) {
        super(props);
        this.interval = null;
        this.textElement = null;
        this.photoElement = null;
        //this.resizePhoto = null;
        this.state = {
            textContent: [],
            photoContent: [
                house_1,
                house_2,
                house_3,
                house_4,
                house_5,
            ],
            classContent: [
                'bg-house_1',
                'bg-house_2',
                'bg-house_3',
                'bg-house_4',
                'bg-house_5'
            ],
            prevIndex: 0,
            contentIndex: 0,
        };
        this.arrFin = [
            "LUOTETTAVIA RATKAISUJA VUODESTA 1996",
            "LAADUKKAAT AUTOMAATIO-, KULUTUSMITTAUS- JA TURVAJÄRJESTELMÄT ERIKSEEN TAI INTEGROITUNA KOKONAISUUTENA",
            "AVOIN RATKAISU – HELPPO PÄIVITETTÄVYYS JA PITKÄ ELINKAARI – JÄRKEVÄ INVESTIOINTI",
            "INTEGROIDUT RATKAISUT ÄLYKKÄISIIN VIHREISIIN RAKENNUKSIIN",
            "SKAALAUTUVA RATKAISU REFERENSEILLÄ YMPÄRI MAAILMAA",
        ];
        this.arrEng = [
            "RELIABLE SOLUTIONS SINCE 1996 FROM FINLAND",
            "HIGH QUALITY AUTOMATION, CONSUMPTION METERING AND SECURITY SYSTEMS INDIVIDUALLY OR AS AN INTEGRATED SOLUTION",
            "OPEN SOLUTION – EASY UPGRADES AND LONG LIFE CYCLE – SOUND INVESTMENT",
            "FOREFRONT IBMS FOR INTELLIGENT GREEN BUILDINGS",
            "SCALABLE SOLUTION WITH WORLD WIDE REFERENCES",
        ];

        this.stylePreparations = this.stylePreparations.bind(this);
        this.toggleContentIndex = this.toggleContentIndex.bind(this);
        this.handleContentIndex = this.handleContentIndex.bind(this);
        this.setActiveClass = this.setActiveClass.bind(this);
        //this.setImageSize = this.setImageSize.bind(this);
    }

    componentDidMount() {
        this.stylePreparations();
        this.handleContentIndex();

        /*window.addEventListener("resize", (e) => {
            e.preventDefault();
            clearTimeout(this.resizePhoto);
            this.resizePhoto = setTimeout(() => {
                this.setImageSize(document.querySelector('.style').getBoundingClientRect());}, 500);
        });*/
    }

    stylePreparations = () => {
        //this.textElement = document.querySelector('.style .innerStyle .styleText');
        this.photoElement = document.querySelector('.style .innerStyle .photos');

        let arr = this.props.i18n.language.indexOf('en') !== -1 ? this.arrEng : this.arrFin;
        this.setState({textContent: arr});
    };

    /*setImageSize = (object) => {
        for(let i = 0; i < this.photoElement.childNodes.length; i++) {
            let sheetStyle = window.getComputedStyle(this.photoElement.childNodes[i]);
            let sizeX = object.width / 1680 * 100;
            let sizeY = object.height / 981 * 100;
            this.photoElement.childNodes[i].style.backgroundSizeX = sizeX.toFixed(2)+"%";
            this.photoElement.childNodes[i].style.backgroundSizeY = sizeY.toFixed(2)+"%";
            let posX = (Math.abs(parseInt(sheetStyle.backgroundPositionX)) / Math.abs(object.width - 1681));
            let posY = (Math.abs(parseInt(sheetStyle.backgroundPositionY)) / Math.abs(object.height - 981));
            this.photoElement.childNodes[i].style.backgroundPosition = posX.toFixed(2)+"% "+posY.toFixed(2)+"%";
        }
    };*/

    setActiveClass = () => {
        for(let i = 0; i < this.photoElement.childNodes.length; i++){
            if(i === this.state.contentIndex){
                this.photoElement.childNodes[i].classList.toggle('activePhoto', true);
                this.photoElement.childNodes[i].firstChild.classList.toggle('activeText', true);
                //this.textElement.childNodes[i].classList.toggle('activeText', true);
            }else{
                this.photoElement.childNodes[i].classList.toggle('activePhoto', false);
                this.photoElement.childNodes[i].firstChild.classList.toggle('activeText', false);
                //this.textElement.childNodes[i].classList.toggle('activeText', false);
            }
        }
        //this.photoElement.querySelector("#\\3"+this.state.contentIndex+" ").classList.toggle('activePhoto', true);
    };

    handleContentIndex = () => {
        clearInterval(this.interval);

        this.setActiveClass();
        //this.textElement.classList.toggle('run-animation', false);

        this.interval = setInterval((() => {
            //setTimeout(()=>{this.textElement.classList.toggle('run-animation', true);}, 50);
            //this.textElement.classList.toggle('run-animation', false);

            let nextIndex = this.state.contentIndex + 1 === this.state.textContent.length ? 0 : this.state.contentIndex + 1;
            this.setState({prevIndex: this.state.contentIndex, contentIndex: nextIndex});

            this.setActiveClass();
        }), 4050);

    };

    toggleContentIndex = (operator) => {
        let arrLength = this.state.textContent.length-1;
        let nextIndex = this.state.contentIndex;

        if(operator === "+"){nextIndex = nextIndex + 1 > arrLength ? 0 : nextIndex + 1;}
        else if(operator === "-"){nextIndex = nextIndex - 1 < 0 ? arrLength : nextIndex - 1;}

        this.setState({contentIndex: nextIndex}, this.handleContentIndex);
    };

    render() {
        return (
            <div className="style">
                <div className="innerStyle"/*style={{backgroundImage: "url(" + this.state.photoContent[this.state.contentIndex] + ")"}}  className={this.state.classContent[0]} */ >
                    <div className="photos">
                        <div id="0" className={this.state.classContent[0]}><div className="textDiv">{this.state.textContent[0]}</div></div>
                        <div id="1" className={this.state.classContent[1]}><div className="textDiv">{this.state.textContent[1]}</div></div>
                        <div id="2" className={this.state.classContent[2]}><div className="textDiv">{this.state.textContent[2]}</div></div>
                        <div id="3" className={this.state.classContent[3]}><div className="textDiv">{this.state.textContent[3]}</div></div>
                        <div id="4" className={this.state.classContent[4]}><div className="textDiv">{this.state.textContent[4]}</div></div>
                    </div>
                    <button name="leftButton" className="styleToggle" onClick={() => this.toggleContentIndex("-")}> <strong>&#8249;</strong> </button>
                    <button name="rightButton" className="styleToggle" onClick={() => this.toggleContentIndex("+")}> <strong>&#8250;</strong> </button>
                </div>
            </div>
        );
    }
}


class Hamburger extends Component {
    render() {
        return(
            <button className="mobileMenuButton" >
                <div className="bar1"/>
                <div className="bar2"/>
                <div className="bar3"/>
            </button>
        );
    }
}

class Notfound extends Component {

    render() {
        console.log("Not found main.js: ",this.props.t('header.notfound'));
        return (
            <h1 style={{fontSize: '35px', marginTop: '30px'}}>404 {this.props.t('header.notfound')}</h1>
        )
    }
}



