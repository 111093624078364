import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

class Info extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidePDFS: null,
        };

        this.handlePageSidePDFs = this.handlePageSidePDFs.bind(this);
    }

    handlePageSidePDFs = (sheet) => {
        let pdfsParsed = this.props.pdfs.map(function (item, i) {
            let name = item.split('/')[3].split('.')[0];
            return(
                <div key={i}>
                    <a href={item} target="_blank" rel="noopener noreferrer">
                    <img alt="" title="Download"/></a>
                    <span>{name.replace(/_|-|Datasheet|Lonix|LX-SEC/g, ' ')/*.concat(' ',sheet)*/}</span>
                </div>
            );
        });

        this.setState({sidePDFS: pdfsParsed});
    };

    componentDidMount() {
        this.handlePageSidePDFs(this.props.t('general.sheet'));
    }
    componentWillUnmount() {
        //this.handlePageSidePDFs();
    }
    render() {
        const { t, pageName } = this.props;

        const noSides = ['contact', 'home', 'products', 'components', 'news', 'automation', 'security', 'videosurveillance'];
        const noShort = ['support', 'components', 'contact', 'news'];

        return (
            <div className="infoPage">

                <div className="currentOpenHeader">
                    {pageName.indexOf('home') === -1 ?
                        <h2>{t('header.'+pageName)}</h2> :
                        <h2>{t('header.home2')}</h2>
                    }
                    <h3>{t(pageName+'.info.h_1')}</h3>
                </div>

                {noShort.indexOf(pageName) === -1 ?
                    <div className="shortBody">
                        <p>{t(pageName+'.info.t_1')}</p>
                    </div>
                    : null
                }

                {noSides.indexOf(pageName) === -1 ?
                    <div className="sidePDFs">
                        <h4>{t('header.resources')}</h4>
                        {this.state.sidePDFS}
                        <br/>
                    </div>
                    : null
                }

            </div>
        );
    }
}
export default withTranslation()(Info);

